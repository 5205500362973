import React, { useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import { theme } from 'theme';
import { PageContextType } from 'types';

import Layout from 'components/templates/layout';
import ContactForm, { ContactFormType } from 'components/organisms/contact-form/contact-form';
import ContactUsDirectlySection, {
  ContactUsDirectlySectionType,
} from 'components/organisms/contact-us-directly-section';
import SendedInfo, { SendedInfoType } from 'components/organisms/sended-info';
import IndustriesBanner, { IndustriesBannerType } from 'components/molecules/industries-banner';

type DataProps = {
  commonComponents: {
    commonComponentsData: {
      contactUsDirectly: ContactUsDirectlySectionType;
    };
  };
  ContactPage: {
    template: {
      contactData: {
        bannerQuaternary: IndustriesBannerType;
        contactForm: ContactFormType;
        afterSendBox: SendedInfoType;
      };
    };
  };
};

const ContactPage = ({
  pageContext,
  data: {
    ContactPage: {
      template: {
        contactData: { bannerQuaternary, contactForm, afterSendBox },
      },
    },
    commonComponents: {
      commonComponentsData: { contactUsDirectly },
    },
  },
}: PageProps<DataProps, PageContextType>) => {
  const [sendedForm, setSendedForm] = useState(false);

  return (
    <Layout {...{ pageContext }}>
      <IndustriesBanner
        isWhiteBreadcrumb
        {...bannerQuaternary}
        pageContext={pageContext}
        isPaddingText
      />
      {sendedForm ? (
        <SendedInfo
          {...afterSendBox}
          newRequest={() => setSendedForm(false)}
          mobilePadding={`${theme.gap.mdl} ${theme.gap.sm}`}
        />
      ) : (
        <ContactForm
          {...contactForm}
          sendedEmail={() => setSendedForm(true)}
          mobilePadding={`${theme.gap.mdl} ${theme.gap.sm}`}
        />
      )}
      <ContactUsDirectlySection
        padding={`${theme.gap.xl} 0`}
        {...contactUsDirectly}
        mobilePadding={`${theme.gap.mdl} ${theme.gap.sm}`}
      />
    </Layout>
  );
};

export const query = graphql`
  query ContactPage($id: String, $commonComponentsSlug: String!) {
    ContactPage: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Contact {
          contactData {
            bannerQuaternary {
              heading
              text
              image {
                ...WpImageFragment
              }
            }
            contactForm {
              sendEmail
              button {
                label
              }
              company {
                label
              }
              consent {
                text
              }
              description
              email {
                label
              }
              firstName {
                label
              }
              lastName {
                label
              }
              heading
              message {
                label
              }
              phone {
                label
              }
              topic {
                label
                options {
                  text
                }
              }
            }
            afterSendBox {
              text
              heading
              button {
                icon {
                  name
                }
                label
              }
            }
          }
        }
      }
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        contactUsDirectly {
          heading
          contactCards {
            title
            place
            data
            contactList {
              linkButton {
                label
                icon {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ContactPage;
